
export default function ContactMe () {



    return (
        <section id="ContactMe" className="contact--section">
            <div>
                <p className="sub--title">Got a question?</p>
                <h2>Get In Touch 🏗️</h2>
                <p className="text-lg">
                    Lets work together to build something great. 
                </p>
            </div>
            <form className="contact--form--container" name="portfolio-contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="portfolio-contact" />
                <div className="container">
                    <label htmlFor="first-name" className="contact--label">
                        <span className="text-md">First Name</span>
                        <input type="text" className="contact--input text-md" name="first-name" id="first-name" required ></input>
                    </label>
                    <label htmlFor="last-name" className="contact--label">
                        <span className="text-md">Last Name</span>
                        <input type="text" className="contact--input text-md" name="last-name" id="last-name" required></input>
                    </label>
                    <label htmlFor="email" className="contact--label email">
                        <span className="text-md">Email</span>
                        <input type="email" className="contact--input text-md" name="email" id="email" required></input>
                    </label>
                </div>
                <label htmlFor="message" className="contact--label">
                        <span className="text-md">Message</span>
                        <textarea className="contact--input text-md" id="message" rows="8" placeholder="Type message here..." name="message"></textarea>
                </label>
                <div>
                    <button className="btn contact--form--bt" type="submit">Send Message</button>
                </div>
            </form>
        </section>
    );
}