export default function Experience() {
    return (
        <section id="AboutMe" className="about--section">
            
            <div className="
            about--section--content--box">
                <div className="about--section-content">
                    <h1 className="skills-section--heading">Experience</h1>
                    <p className="about--section-description">
                    My tech journey ignited in 2019, catalysed by challenges that called for intelligent automation at a music tech company. It wasn't long before I was deep in the books again, snagging a Distinction in Computer Science from the University of Birmingham and getting a taste for the sweet, complex symphony of software dev.
                    </p>
                    <p className="about--section-description">
                    Out in the wilds of the tech world, my toolkit grew. I’ve woven together APIs in Java, C#, and Python, crafting each one to fit just right into the tech tapestry of their respective projects. Whether it’s serverless magic or microservices, I’ve been there, making sure things are running smooth and swift. 
                    </p>
                    <p className="about--section-description">
                    Cut to now: I’m steering a cinema stats project that’s all about smart APIs and machine learning. This beast of a project has me doing everything from low-level processors and durable functions to finessing deployment pipelines and UIs.
                    </p>
                    <p className="about--section-description">
                    Swing by my portfolio to see the stories behind the solutions. It’s the scoop on my tech journey so far and a peek at where the path is heading next.
                    </p>
                </div>
            </div>
        </section>
    )
}





